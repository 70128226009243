/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { getOportunidades } from "../../utils";
import "./styles.scss";

export const VentasTablaEjecutivo = ({ filtroEjecutivo }) => {
  const [oportunidades, setOportunidades] = useState([]);
  const [filtroAno] = useState("2025"); // Fixed to 2025
  const { pais, rol, id } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "$ ",
    []
  );

  const objetivosPorMes2025 = useCallback((rol, id) => {
    const objetivosPorId = {
      32: [ // ID de 'Ángeles'
        11808054,
        5284024,
        18368084,
        31688672,
        21125781,
        30368310,
        35228630,
        28823424,
        32026027,
        46966329,
        30192640,
        16773689,
      ],
      270: [ // ID de 'Ventas2'
        0, 0, 2755213,
        7922168,
        5281445,
        7592078,
        17614315,
        14411712,
        16013014,
        23483164,
        15096320,
        8368844,
      ],      
      6: [ // ID de 'Santiago'
        100000, 
        100000, 
        100000,
        100000,
        100000,
        100000,
        100000,
        100000,
        100000,
        100000,
        100000,
        100000,
      ],
      1: [ // ID de 'Ignacio'
        1000000, 
        1000000, 
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
      ],
      10: [ // ID de 'Gabriel'
        1000000, 
        1000000, 
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
        1000000,
      ],
    };
  
    if (rol === "comercial") {
      // Para rol comercial, usamos el id del usuario logeado
      return id in objetivosPorId ? objetivosPorId[id] : Array(12).fill(0);
    } else if (rol === "gerencia" || rol === "superadmin") {
      // Para gerencia o superadmin, usamos filtroEjecutivo si está disponible, de lo contrario el id del usuario logeado
      return (filtroEjecutivo in objetivosPorId ? objetivosPorId[filtroEjecutivo] : 
          (id in objetivosPorId ? objetivosPorId[id] : Array(12).fill(0)));
    }
  
    // En caso de que el rol no se reconozca, devolver un array de ceros
    return Array(12).fill(0);
  }, [filtroEjecutivo]);

  const objetivosPorMes = useCallback((rol, ejecutivoId) => {
    // Utilizamos filtroEjecutivo si está disponible, de lo contrario, usamos id para 'comercial'
    const efectivoId = ejecutivoId || (rol === "comercial" ? id : "");
    return objetivosPorMes2025(rol, efectivoId) || [];
  }, [objetivosPorMes2025, id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ejecutivoId = rol === "comercial" ? id : filtroEjecutivo || id;
        const allOportunidades = await getOportunidades({ pais, ano: filtroAno });
        const filteredOportunidades = allOportunidades.filter(oportunidad => {
          const matchesEjecutivo = oportunidad.ejecutivo === ejecutivoId.toString(); 
          const matchesAno = String(oportunidad.ano) === filtroAno;
          const matchesEliminada = (oportunidad.eliminada === null || oportunidad.eliminada === false);
        
          return matchesEjecutivo && matchesAno && matchesEliminada;
        });
        
        setOportunidades(filteredOportunidades);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };
  
    fetchData();
  }, [pais, rol, filtroAno, filtroEjecutivo, id]);

  const getMonthName = (index) => {
    const months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return months[index];
  };

  const getMonthStatus = (monthIndex, status) =>
    oportunidades
      .filter((oportunidad) => parseInt(oportunidad.mes) - 1 === monthIndex && oportunidad.estatus === status)
      .reduce((total, oportunidad) => total + (oportunidad.importe || 0), 0);

  const getQuarterSubtotal = (quarterIndex, status) => {
    const startMonth = quarterIndex === 1 ? 1 : quarterIndex === 3 ? 4 : quarterIndex === 6 ? 7 : 10;
    const endMonth = quarterIndex === 1 ? 3 : quarterIndex === 3 ? 6 : quarterIndex === 6 ? 9 : 12;
    return oportunidades
      .filter(
        (oportunidad) =>
          parseInt(oportunidad.mes) >= startMonth &&
          parseInt(oportunidad.mes) <= endMonth &&
          oportunidad.estatus === status &&
          !oportunidad.eliminada
      )
      .reduce((total, oportunidad) => total + (oportunidad.importe || 0), 0);
  };

  const getHalfSubtotal = (halfIndex, status) => {
    const startMonth = halfIndex === 1 ? 1 : 7;
    const endMonth = halfIndex === 1 ? 6 : 12;
    return oportunidades
      .filter(
        (oportunidad) =>
          parseInt(oportunidad.mes) >= startMonth &&
          parseInt(oportunidad.mes) <= endMonth &&
          oportunidad.estatus === status &&
          !oportunidad.eliminada
      )
      .reduce((total, oportunidad) => total + (oportunidad.importe || 0), 0);
  };

  const renderSubtotal = (subtotal) => (
    <td className="text-end">
      {moneda(pais)}
      {subtotal !== undefined ? subtotal.toLocaleString(localString(pais), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) : "0"}
    </td>
  );

  const renderCumplimiento = (quarterOrHalf, total) => (
    <td className="text-center">
      {quarterOrHalf !== 0 && total !== undefined ? 
          ((total / quarterOrHalf) * 100).toLocaleString(localString(pais), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }) + "%" : "0%"}
    </td>
  );

  const renderMissing = (acumuladoObjetivo, acumuladoTotal) => {
    const diferencia = acumuladoObjetivo !== undefined && acumuladoTotal !== undefined ? acumuladoTotal - acumuladoObjetivo : 0;
    return (
      <td className={`text-end ${diferencia < 0 ? "text-danger" : "text-black"}`}>
        {diferencia >= 0 ? "No Hay!" : `${moneda(pais)}-${Math.abs(diferencia).toLocaleString(localString(pais), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}`}
      </td>
    );
  };

  const totalYTD = objetivosPorMes(rol, id).reduce((total, monto) => total + (monto || 0), 0);

  const objetivosAcumulados = useCallback((objetivos) => {
    const acumulado = [];
    let total = 0;
    for (let i = 0; i < objetivos.length; i++) {
      total += objetivos[i];
      acumulado.push(total);
    }
    return acumulado;
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <table className="table table-striped table-hover table-bordered table-sm" style={{ width: "80%" }}>
        <thead className="table-dark text-center align-middle">
          <tr>
            <th scope="col" style={{ width: "5%" }}>MES</th><th scope="col" style={{ width: "8%" }}>OBJETIVO</th>
            <th scope="col" colSpan="2" style={{ width: "8%" }} className="bg-success text-white">100%</th>
            <th scope="col" colSpan="2" style={{ width: "8%", backgroundColor: "#4B0082", color: "white" }}>OBJETIVO YTD</th>
            <th scope="col" style={{ width: "8%" }}>PENDIENTE YTD</th>
            <th scope="col" style={{ width: "8%" }} className="bg-danger text-white">90%</th>
            <th scope="col" style={{ width: "8%" }} className="bg-primary text-white">75%</th>
          </tr>
        </thead>
        <tbody>
          {/* Monthly data */}
          {[...Array(12)].map((_, index) => {
            const objetivos = objetivosPorMes2025(rol, filtroEjecutivo ? filtroEjecutivo : id);
            const objetivosAcum = objetivosAcumulados(objetivos);
            const total100 = getMonthStatus(index, "100%");
            const totalAcumulado100 = oportunidades
              .filter((oportunidad) => parseInt(oportunidad.mes) - 1 <= index && oportunidad.estatus === "100%" && !oportunidad.eliminada)
              .reduce((total, oportunidad) => total + (oportunidad.importe || 0), 0);

            return (
              <tr key={index}>
                <th scope="row" className="text-center">{getMonthName(index)}</th>
                <td className="text-end">
                  {moneda(pais)}
                  {objetivos[index] !== undefined ? objetivos[index].toLocaleString(localString(pais), { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : "0"}
                </td>
                {renderSubtotal(total100)}
                {renderCumplimiento(objetivos[index] || 0, total100)}
                <td className="text-end">
                  {moneda(pais)}
                  {objetivosAcum[index] !== undefined ? objetivosAcum[index].toLocaleString(localString(pais), { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : "0"}
                </td>
                <td className="text-center">
                  {totalAcumulado100 !== undefined && objetivosAcum[index] !== undefined && objetivosAcum[index] !== 0 ? 
                      ((totalAcumulado100 / objetivosAcum[index]) * 100).toFixed(0) + "%" : "0%"}
                </td>
                {renderMissing(objetivosAcum[index], totalAcumulado100)}
                {renderSubtotal(getMonthStatus(index, "90%"))}
                {renderSubtotal(getMonthStatus(index, "75%"))}
              </tr>
            );
          })}

          {/* YTD */}
          <tr className="table-dark">
            <th scope="row" className="text-center">YTD</th>
            <td className="text-end">
              {moneda(pais)}
              {totalYTD.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            {renderSubtotal(getHalfSubtotal(1, "100%") + getHalfSubtotal(2, "100%"))}
            {renderCumplimiento(totalYTD, getHalfSubtotal(1, "100%") + getHalfSubtotal(2, "100%"))}
            <td className="text-end">
              {moneda(pais)}
              {totalYTD.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-center">
              {totalYTD !== 0 ? 
                  (((getHalfSubtotal(1, "100%") + getHalfSubtotal(2, "100%")) / totalYTD) * 100).toFixed(0) + "%" : "0%"}
            </td>
            {renderMissing(totalYTD, getHalfSubtotal(1, "100%") + getHalfSubtotal(2, "100%"))}
            {renderSubtotal(getHalfSubtotal(1, "90%") + getHalfSubtotal(2, "90%"))}
            {renderSubtotal(getHalfSubtotal(1, "75%") + getHalfSubtotal(2, "75%"))}
          </tr>

          {/* Quarters */}
          {[1, 3, 6, 9].map((quarterIndex, idx) => {
            const totalQ = objetivosPorMes(rol, id)
              .slice(idx * 3, (idx + 1) * 3)
              .reduce((total, monto) => total + (monto || 0), 0);
            const totalQ100 = getQuarterSubtotal(quarterIndex, "100%");
            const totalQ90 = getQuarterSubtotal(quarterIndex, "90%");
            const totalQ75 = getQuarterSubtotal(quarterIndex, "75%");

            return (
              <tr key={`q${quarterIndex}`}>
                <th scope="row" className="text-center">Q{idx + 1}</th>
                <td className="text-end">
                  {moneda(pais)}
                  {totalQ.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                {renderSubtotal(totalQ100)}
                {renderCumplimiento(totalQ, totalQ100)}
                <td className="text-end">
                  {moneda(pais)}
                  {totalQ.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td className="text-center">
                  {totalQ !== 0 ? 
                      ((totalQ100 / totalQ) * 100).toFixed(0) + "%" : "0%"}
                </td>
                {renderMissing(totalQ, totalQ100)}
                {renderSubtotal(totalQ90)}
                {renderSubtotal(totalQ75)}
              </tr>
            );
          })}

          {/* Halves */}
          {[1, 2].map((halfIndex) => {
            const totalH =
              halfIndex === 1
                ? objetivosPorMes(rol, id)
                  .slice(0, 6)
                  .reduce((total, monto) => total + (monto || 0), 0)
                : objetivosPorMes(rol, id)
                  .slice(6)
                  .reduce((total, monto) => total + (monto || 0), 0);
            const totalH100 = getHalfSubtotal(halfIndex, "100%");
            const totalH90 = getHalfSubtotal(halfIndex, "90%");
            const totalH75 = getHalfSubtotal(halfIndex, "75%");

            return (
              <tr key={`h${halfIndex}`} className="table-dark">
                <th scope="row" className="text-center">H{halfIndex}</th>
                <td className="text-end">
                  {moneda(pais)}
                  {totalH.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                {renderSubtotal(totalH100)}
                {renderCumplimiento(totalH, totalH100)}
                <td className="text-end">
                  {moneda(pais)}
                  {totalH.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td className="text-center">
                  {totalH !== 0 ? 
                      ((totalH100 / totalH) * 100).toFixed(0) + "%" : "0%"}
                </td>
                {renderMissing(totalH, totalH100)}
                {renderSubtotal(totalH90)}
                {renderSubtotal(totalH75)}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};