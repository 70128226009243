/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import { Container, ListaEdificios } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getEdificios, saveUsuario, getUsers, getIntermediarios, updateUsuario } from "../../utils";

import "./styles.scss";

export const Usuarios = () => {
  const [edificios, setEdificios] = useState([]);
  const methods = useForm(); // Inicializa el form context
  const [usuarios, setUsuarios] = useState([]);
  const [sortColumn, setSortColumn] = useState("nombre");
  const [sortDirection, setSortDirection] = useState("asc");
  const [newUser, setNewUser] = useState({
    nombre: "",
    apellido: "",
    email: "",
    rol: "",
    edificiosOk: "",
    agencia: "",
  });
  const [showListaEdificios, setShowListaEdificios] = useState(false);
  const [intermediarios, setIntermediarios] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { pais, rol } = useAuthContext();

  useEffect(() => {
    const fetchEdificios = async () => {
      try {
        const data = await getEdificios({ pais });
        setEdificios(data);
      } catch (error) {
        console.error("Error al obtener edificios:", error);
      }
    };

    fetchEdificios();
  }, [pais]);

  const fetchUsuarios = async () => {
    try {
      const newUsuarios = await getUsers();
      setUsuarios(newUsuarios);
    } catch (error) {
      console.error("Error al obtener usuarios:", error);
    }
  };

  useEffect(() => {
    fetchUsuarios();
    const fetchIntermediarios = async () => {
      try {
        const newIntermediarios = await getIntermediarios({ pais });
        setIntermediarios(newIntermediarios);
      } catch (error) {
        console.error("Error al obtener intermediarios:", error);
      }
    };

    fetchIntermediarios();
  }, [pais]);

  const handleEditClick = (usuario) => {
    setEditingUser(usuario);
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    if (editingUser && editingUser.id) {
      try {
        const updatedUser = await updateUsuario({
          id: editingUser.id,
          email: editingUser.email,
          agencia: editingUser.agencia !== undefined ? parseInt(editingUser.agencia, 10) : null,
        });

        if (updatedUser) {
          setUsuarios((prevUsers) =>
            prevUsers.map((user) => (user.id === updatedUser.id ? { ...user, ...updatedUser } : user))
          );
          setEditingUser(null);
          setIsEditing(false);

          // Fetch users again to ensure the list is updated with the latest from the server
          const newUsuarios = await getUsers();
          setUsuarios(newUsuarios);

          setTimeout(() => {}, 1000);
        } else {
          console.error("Updated user data not returned correctly");
        }
      } catch (error) {
        console.error("Error saving user:", error);
      }
    }
  };

  const handleEmailChange = (e) => {
    if (editingUser) {
      setEditingUser({ ...editingUser, email: e.target.value });
    }
  };

  const handleAgenciaChange = (e) => {
    if (editingUser) {
      setEditingUser({ ...editingUser, agencia: e.target.value }); // The value here should be the ID (number)
    }
  };

  const getNombresEdificios = (edificiosOk) => {
    // Si edificiosOk es una cadena, conviértela en un array de números
    if (typeof edificiosOk === "string") {
      edificiosOk = edificiosOk.split(",").map((id) => parseInt(id.trim(), 10));
    }

    if (!edificiosOk || !Array.isArray(edificiosOk)) return "No Aplica";

    const nombres = edificios
      .filter((edificio) => edificiosOk.includes(edificio.id))
      .map((edificio) => edificio.edificio)
      .join(", ");

    return nombres || "No Aplica";
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection(sortColumn === column && sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedUsuarios = usuarios
    .filter((usuario) => {
      const validPais =
        (pais === "Uruguay" && usuario.pais === "URU") ||
        (pais === "Perú" && usuario.pais === "PER") ||
        (pais === "Chile" && usuario.pais === "CHI");

      const validRole =
        rol === "comercial"
          ? usuario.rol === "agencia"
          : !["superadmin", "comercial", "administrativo"].includes(usuario.rol);

      return validPais && validRole;
    })
    .sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Actualiza el nuevo usuario con el valor del input
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    // Si el campo cambiado es 'rol', actualiza showListaEdificios en consecuencia
    if (name === "rol") {
      // Muestra la lista de edificios solo si el rol seleccionado es "edificio"
      setShowListaEdificios(value === "edificio");

      // Si el rol es diferente de "edificio", limpia los edificios seleccionados
      if (value !== "edificio") {
        setNewUser((prevUser) => ({
          ...prevUser,
          edificiosOk: "",
        }));
      }
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    // Obtener edificios seleccionados desde el form context
    const edificiosSeleccionados = methods.getValues("edificiosSeleccionados");

    const userWithCountryAndIntermediary = {
      ...newUser,
      pais:
        {
          Uruguay: "URU",
          Perú: "PER",
          Chile: "CHI",
        }[pais] || "",
      edificiosOk: edificiosSeleccionados,
      agencia: newUser.agencia,
      // Ensure 'rol' is included in the data sent to Supabase
      rol: rol === "comercial" ? "agencia" : newUser.rol, // If 'comercial', set role to 'agencia'
    };

    try {
      const data = await saveUsuario(userWithCountryAndIntermediary);

      if (Array.isArray(data)) {
        setUsuarios([...usuarios, ...data]);
      } else if (data) {
        setUsuarios([...usuarios, data]);
      }

      setNewUser({
        nombre: "",
        apellido: "",
        email: "",
        rol: "",
        edificiosOk: "",
        agencia: "",
      });
      setTimeout(() => {
        window.location.reload(); // Refresca toda la página
      }, 1000);
    } catch (error) {
      console.error("Error al agregar usuario:", error);
    }
  };

  return (
    <Container className="Usuarios" header titulo="Usuarios">
      <FormProvider {...methods}>
        <form onSubmit={handleAddUser} className="row justify-content-center align-items-center mb-4">
          <div className="col-2">
            <input
              type="text"
              className="form-control mb-2"
              id="nombre"
              name="nombre"
              placeholder="Nombre"
              value={newUser.nombre}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control mb-2"
              id="apellido"
              name="apellido"
              placeholder="Apellido"
              value={newUser.apellido}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-2">
            <input
              type="email"
              className="form-control mb-2"
              id="email"
              name="email"
              placeholder="Correo"
              value={newUser.email}
              onChange={handleInputChange}
              required
            />
          </div>
          {rol !== "comercial" && (
            <div className="col-2">
              <select
                className="form-control mb-2"
                id="rol"
                name="rol"
                value={newUser.rol}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                <option value="agencia">Agencia</option>
                <option value="edificio">Edificio</option>
              </select>
            </div>
          )}
          {rol === "comercial" && <input type="hidden" name="rol" value="agencia" />}
          <div className="col-2">
            <select
              className="form-control mb-2"
              id="agencia"
              name="agencia"
              value={newUser.agencia || ""}
              onChange={handleInputChange}
            >
              <option value="">Seleccionar Intermediario</option>
              {intermediarios.map((intermediario) => (
                <option key={intermediario.id} value={intermediario.id}>
                  {intermediario.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-2">
              Agregar Usuario
            </button>
          </div>
        </form>
        {showListaEdificios && (
          <div className="mb-5">
            <ListaEdificios
              forceSubmit={() => {
                const edificiosSeleccionados = methods.getValues("edificiosSeleccionados");
                setNewUser((prevState) => ({
                  ...prevState,
                  edificiosOk: edificiosSeleccionados,
                }));
              }}
            />
          </div>
        )}
      </FormProvider>
      <table className="table table-fixed table-bordered table-hover align-middle">
        <thead className="table-dark text-center align-middle">
          <tr>
            <th width="10%" onClick={() => handleSort("nombre")} style={{ cursor: "pointer" }}>
              Nombre {sortColumn === "nombre" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th width="10%" onClick={() => handleSort("apellido")} style={{ cursor: "pointer" }}>
              Apellido {sortColumn === "apellido" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th width="25%" onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
              Correo {sortColumn === "email" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th width="15%" onClick={() => handleSort("agencia")} style={{ cursor: "pointer" }}>
              Agencia {sortColumn === "agencia" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            {rol !== "comercial" && (
              <th width="10%" onClick={() => handleSort("rol")} style={{ cursor: "pointer" }}>
                Rol {sortColumn === "rol" && (sortDirection === "asc" ? "↑" : "↓")}
              </th>
            )}
            {rol !== "comercial" && (
              <th width="30%" onClick={() => handleSort("edificiosOk")} style={{ cursor: "pointer" }}>
                Edificios OK {sortColumn === "edificiosOk" && (sortDirection === "asc" ? "↑" : "↓")}
              </th>
            )}
            <th width="5%">Edit</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsuarios.map((usuario) => (
            <tr key={usuario.id}>
              <td className="text-start">{usuario.nombre}</td>
              <td className="text-start">{usuario.apellido}</td>
              <td className="text-center" style={{ verticalAlign: "middle" }}>
                {isEditing && editingUser && editingUser.id === usuario.id ? (
                  <input
                    type="email"
                    value={editingUser.email}
                    onChange={handleEmailChange}
                    className="form-control mb-2"
                    style={{
                      width: "60%",
                      fontSize: "16px",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  />
                ) : (
                  usuario.email.toLowerCase()
                )}
              </td>
              <td className="text-center" style={{ verticalAlign: "middle" }}>
                {isEditing && editingUser && editingUser.id === usuario.id ? (
                  <select
                    value={editingUser.agencia || ""}
                    onChange={handleAgenciaChange}
                    className="form-control mb-2"
                    style={{
                      width: "60%",
                      fontSize: "16px",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <option value="">Seleccionar</option>
                    {intermediarios.map((intermediario) => (
                      <option key={intermediario.id} value={intermediario.id} style={{ textAlign: "center" }}>
                        {intermediario.nombre}
                      </option>
                    ))}
                  </select>
                ) : (
                  intermediarios.find((i) => i.id === usuario.agencia)?.nombre || "-"
                )}
              </td>
              {rol !== "comercial" && <td className="text-center">{usuario.rol}</td>}
              {rol !== "comercial" && (
                <td className="text-center">
                  {usuario.rol === "agencia" ? "No Aplica" : getNombresEdificios(usuario.edificiosOk)}
                </td>
              )}
              <td className="text-center">
                {isEditing && editingUser && editingUser.id === usuario.id ? (
                  <FiSave onClick={handleSaveClick} style={{ cursor: "pointer" }} />
                ) : (
                  <FaEdit onClick={() => handleEditClick(usuario)} style={{ cursor: "pointer" }} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};
