/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { Container, ListaClientesMisVentas, VentasTablaEjecutivo } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getClientesPublicitarios, getUsers } from "../../utils";
import "./styles.scss";

const VentasEjecutivo = () => {
  const [showTable, setShowTable] = useState(false);
  const [filtroEjecutivo, setFiltroEjecutivo] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const { rol, id, pais } = useAuthContext() || {};

  const toggleTableVisibility = () => {
    setShowTable(!showTable);
  };

  const showTableCondition = () => rol === "comercial" || (rol !== "comercial" && filtroEjecutivo !== "");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const paisCode = pais; // Ajusta según cómo obtienes el código del país
        const [clientesData, usuariosData] = await Promise.all([
          getClientesPublicitarios({ pais: paisCode }),
          getUsers(),
        ]);

        // Obtener ejecutivos únicos de los clientes
        const ejecutivosDelPais = new Set(clientesData.map((cliente) => cliente.ejecutivo));
        const filteredUsuarios = usuariosData.filter(
          (user) =>
            ejecutivosDelPais.has(user.id.toString()) && ["comercial", "gerencia", "superadmin"].includes(user.rol)
        );
        setUsuarios(filteredUsuarios);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    if (rol === "gerencia" || rol === "superadmin") {
      fetchData();
    }
  }, [pais, rol]);

  const handleEjecutivoChange = (e) => {
    setFiltroEjecutivo(e.target.value);
  };

  return (
    <div>
      <Container header titulo={rol === "comercial" ? "MIS VENTAS" : "VENTAS POR EJECUTIVO"}>
        <div className="row justify-content-center">
          <div className="col-md-2 text-center">
            {(rol === "gerencia" || rol === "superadmin") && (
              <select
                className={`form-control ${filtroEjecutivo ? "active-filter" : ""} mb-2`}
                value={filtroEjecutivo}
                onChange={handleEjecutivoChange}
              >
                <option value="">Todo el equipo</option>
                {usuarios.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.nombre} {user.apellido}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="col-md-2 text-center">
            <button
              onClick={toggleTableVisibility}
              className="btn btn-dark"
              disabled={!showTableCondition() && rol !== "comercial"}
            >
              {!showTableCondition() && rol !== "comercial"
                ? "Mostrar Proyección"
                : showTable
                  ? "Ocultar Proyección"
                  : "Mostrar Proyección"}
            </button>
          </div>
        </div>
        {showTable && showTableCondition() && (
          <VentasTablaEjecutivo filtroEjecutivo={rol === "comercial" ? id : filtroEjecutivo} />
        )}
        <ListaClientesMisVentas filtroEjecutivo={rol === "comercial" ? id : filtroEjecutivo} />
      </Container>
    </div>
  );
};

export default VentasEjecutivo;
